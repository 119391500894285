import { authAthletesPageEn } from "./athletesPage";
import { authCurriculumPageEn } from "./curriculumPage";
import { authHomePageMessagesEn } from "./homePage";
import { loginMessages } from "./login";
import { authMakeVideosPageEn } from "./makeVideosPage";
import { authMonitoringPageEn } from "./monitoringPage";
import { authMyProfileMessagesEn } from "./myProfilePage";
import { authProfileAthletePageEn } from "./profileAthletePage";
import { authSearchPageEn } from "./searchPage";
import { authVacanciesPagesEn } from "./vacanciesPage";
import { authVideosPageEn } from "./videosPage";
import { authWelcomePageEn } from "./welcomePage";

export const authMessages = {
    ...authHomePageMessagesEn,
    ...authMyProfileMessagesEn,
    ...loginMessages,
    ...authMonitoringPageEn,
    ...authSearchPageEn,
    ...authVacanciesPagesEn,
    ...authAthletesPageEn,
    ...authProfileAthletePageEn,
    ...authMakeVideosPageEn,
    ...authVideosPageEn,
    ...authCurriculumPageEn,
    ...authWelcomePageEn
}
