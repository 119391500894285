export const authHomePageMessagesEn = {
  'auth.homePage.newProposals': 'New Proposals',
  'auth.homePage.sendProposals': 'Send Proposal',
  'auth.homePage.proposalSentSuccessfully': 'Proposal sent successfully!',
  'auth.homePage.messageToOwner': 'Message to the owner of the proposal:',
  'auth.homePage.confirmRequirements': 'I confirm that I meet all the above requirements.',
  'auth.homePage.submitCV': 'Submit Curriculum',
  'auth.homePage.opportunitiesFound': 'Opportunities Found',
  'auth.homePage.necessaryToHavePlayedInRecentYears': 'Necessary to have played in the last years at least',
  'auth.homePage.opportunityExpired': 'Opportunity Expired',
}
