export const landingPageSuccessCaseMessages = {
    'landingPage.successCase.title': 'Some Success Stories',
    'landingPage.successCase.titlePlayer1': 'MOYSÉS JOINS GOIÁS',
    'landingPage.successCase.descriptionPlayer1':
        'The athlete Moysés signed with Goiás E.C. The connection happened between the club and the company Rx Sports through our platform.',
    'landingPage.successCase.titlePlayer2': 'JARDEL AT ATLÉTICO ALAGOINHAS',
    'landingPage.successCase.descriptionPlayer2': 'The Bahia club secured the signing of the midfielder through the platform.',
    'landingPage.successCase.titlePlayer3': 'MARCOS JOINS VILA NOVA',
    'landingPage.successCase.descriptionPlayer3':
        'Vila Nova, through Transferfut, recruited the fullback Marcos to join their U-20 team.',
    'landingPage.successCase.titlePlayer4': 'Luis Felipe at Guarany F.C',
    'landingPage.successCase.descriptionPlayer4':
        'Through Transferfut, the agencies CCP Sports and Lf90 Sports secured the athlete Luis Felipe at Guarany Futebol Clube.',
    'landingPage.successCase.titlePlayer5': 'LUCAS SOUZA AT SPORTING FONTE NOVA',
    'landingPage.successCase.descriptionPlayer5': 'The club recruited the athlete Lucas Souza through the platform.',
    'landingPage.successCase.titlePlayer6': 'MATHEUS LEAL AT ATLÉTICO ALAGOINHAS',
    'landingPage.successCase.descriptionPlayer6':
        'Atlético Alagoinhas secured the signing of Left Back Matheus Leal. The connection was made through our platform.',
    'landingPage.successCase.titlePlayer7': 'WELLINGTON AT BETIM',
    'landingPage.successCase.descriptionPlayer7':
        'The club Betim secured the signing of the athlete Wellington from the company Pro Talent Sports through our platform.',
    'landingPage.successCase.titlePlayer8': 'CESINHA AT ATLÉTICO ALAGOINHAS',
    'landingPage.successCase.descriptionPlayer8':
        'Atlético Alagoinhas announced the signing of the forward Cesinha through our platform.',
}
