import { landingPageDipositionMessages } from "./diposition";
import { landingPagePlansMessages } from "./plans";
import { landingPageSuccessCaseMessages } from "./successCase";

export const landingPageMessages = {
    'landingPage.wallpaper.title': 'Here begins the connection between Players, Agents, and Clubs.',
    'landingPage.wallpaper.subtitle': 'We build opportunities in the football market: we connect Players, Agents, and Clubs; We accelerate and enhance the hiring and transfer process.',

    'landingPage.partners.title': 'Some of our partners',

    ...landingPageDipositionMessages,
    ...landingPagePlansMessages,
    ...landingPageSuccessCaseMessages
}
