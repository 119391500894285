const commonCountry = {
  'Escolha um País': 'Escolha um País',
}

export const commonCountries = {
  'Sem País': 'Sem País',
  'Albânia': 'Albânia',
  'Andorra': 'Andorra',
  'Argentina': 'Argentina',
  'Armênia': 'Armênia',
  'Austrália': 'Austrália',
  'Áustria': 'Áustria',
  'Azerbaijão': 'Azerbaijão',
  'Bielorrússia': 'Bielorrússia',
  'Bélgica': 'Bélgica',
  'Bolívia': 'Bolívia',
  'Bósnia e Herzegovina': 'Bósnia e Herzegovina',
  'Bulgária': 'Bulgária',
  'Canadá': 'Canadá',
  'Chile': 'Chile',
  'China': 'China',
  'Colômbia': 'Colômbia',
  'Croácia': 'Croácia',
  'Chipre': 'Chipre',
  'República Tcheca': 'República Tcheca',
  'Dinamarca': 'Dinamarca',
  'Equador': 'Equador',
  'Egito': 'Egito',
  'Reino Unido': 'Reino Unido',
  'Estônia': 'Estônia',
  'Finlândia': 'Finlândia',
  'França': 'França',
  'Geórgia': 'Geórgia',
  'Alemanha': 'Alemanha',
  'Grécia': 'Grécia',
  'Hungria': 'Hungria',
  'Islândia': 'Islândia',
  'Índia': 'Índia',
  'Indonésia': 'Indonésia',
  'Irã': 'Irã',
  'Irlanda': 'Irlanda',
  'Israel': 'Israel',
  'Itália': 'Itália',
  'Japão': 'Japão',
  'Cazaquistão': 'Cazaquistão',
  'Kuwait': 'Kuwait',
  'Lituânia': 'Lituânia',
  'Luxemburgo': 'Luxemburgo',
  'Malásia': 'Malásia',
  'Malta': 'Malta',
  'México': 'México',
  'Moldávia': 'Moldávia',
  'Montenegro': 'Montenegro',
  'Holanda': 'Holanda',
  'Nova Zelândia': 'Nova Zelândia',
  'Macedônia': 'Macedônia',
  'Irlanda do Norte': 'Irlanda do Norte',
  'Noruega': 'Noruega',
  'Paraguai': 'Paraguai',
  'Peru': 'Peru',
  'Polônia': 'Polônia',
  'Portugal': 'Portugal',
  'Catar': 'Catar',
  'Romênia': 'Romênia',
  'Rússia': 'Rússia',
  'Arábia Saudita': 'Arábia Saudita',
  'Sérvia': 'Sérvia',
  'Cingapura': 'Cingapura',
  'Eslováquia': 'Eslováquia',
  'Eslovênia': 'Eslovênia',
  'África do Sul': 'África do Sul',
  'Coreia do Norte': 'Coreia do Norte',
  'Espanha': 'Espanha',
  'Suécia': 'Suécia',
  'Suíça': 'Suíça',
  'Tailândia': 'Tailândia',
  'Turquia': 'Turquia',
  'Ucrânia': 'Ucrânia',
  'Emirados Árabes Unidos': 'Emirados Árabes Unidos',
  'Uruguai': 'Uruguai',
  'Estados Unidos': 'Estados Unidos',
  'Venezuela': 'Venezuela',
  'Vietnã': 'Vietnã',
  'País de Gales': 'País de Gales',
  'Brasil': 'Brasil',
  ...commonCountry
}
