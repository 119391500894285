import { commonCountriesEn } from "./countries"

const commonFormEn = {
  'common.form.email': 'Email',
  'common.form.fullName': 'Full Name',
  'common.form.telephone': 'Telephone',
  'common.form.password': 'Enter a password',
  'common.form.passwordConfirmation': 'Confirm password',
  'common.form.mainPosition': 'Main Position',
  'common.form.secondaryPosition': 'Secondary Position',
  'common.form.birthdate': 'Birthdate',
  'common.form.nationality': 'Nationality',
  'common.form.doubleNationality': 'Double Nationality',
  'common.form.isThePlayerAvailable': 'Is the player currently available?',
  'common.form.dominantFoot': 'Dominant Foot',
  'common.form.playerVisibility': 'Player Visibility',
  'common.form.onlyClubs': 'Only Clubs',
  'common.form.clubsAndAgents': 'Clubs and Agents',
  'common.form.agentName': 'Agent Name',
  'common.form.clubName': 'Club Name',
  'common.form.startDate': 'Start Date',
  'common.form.finalDate': 'End Date',
  'common.form.hasAgent': 'Has Agent?',
  'common.form.nameAgency': 'Agency Name',
}

const commonAlertsEn = {
    'common.alerts.profileBeingEvaluated': 'Your profile is being evaluated by our team. We will contact you soon via WhatsApp using the phone number provided in your registration.',
    'common.alerts.selectOnePlayerToRegisterOpportunity': 'Select at least 1 player to register for the opportunity!',
    'common.alerts.alreadyRegisteredForThisOpportunity': 'You have already registered for this opportunity!',
    'common.alerts.meetAllOpportunityRequirements': 'If you meet all the requirements of the opportunity, the club/agent will receive your resume with your contact details.',
    'common.alerts.noPlayersRegistered': 'You have no registered players. Register at least one to apply for the proposal!',
}

const commonPositionsEn = {
    'common.position': 'Position',
    'common.goalkeeper': 'Goalkeeper',
    'common.defender': 'Defender',
    'common.rightBack': 'Right Back',
    'common.leftBack': 'Left Back',
    'common.defensiveMidfielder': 'Defensive Midfielder',
    'common.midfielder': 'Midfielder',
    'common.winger': 'Winger',
    'common.striker': 'Striker',
    'common.coach': 'Coach',
    'common.agent': 'Agent',
    'common.club': 'Club',
    'common.player': 'Player'
}

const commonMenuEn = {
    'common.myPlayers': 'My Players',
    'common.search': 'Search',
    'common.myProfile': 'My Profile',
    'common.proposals': 'Proposals',
    'common.monitoring': 'Monitoring',
    'common.myVacancies': 'My Vacancies',
    'common.myVideos': 'My Videos',
}

const commonLevelsEn = {
  'common.level.a': 'Series A',
  'common.level.b': 'Series B',
  'common.level.c': 'Series C',
  'common.level.d': 'Series D',
  'common.level.stateDivision': '1st State Division',
  'common.level.base': 'Youth',
  'common.level.anyDivision': 'Any Division',
  'common.level.undefined': 'Undefined'
}

const commonCategoryEn = {
  'common.base': 'Youth',
  'common.professional': 'Professional',
}

export const commonEn = {
    'common.start': 'Start',
    'common.login': 'Login',
    'common.clubs': 'Clubs',
    'common.agents': 'Agents',
    'common.players': 'Players',
    'common.plans': 'Plans',
    'common.yes': 'Yes',
    'common.not': 'No',
    'common.save': 'Save',
    'common.optional': 'Optional',
    'common.attention': 'Attention',
    'common.yearsOld': '{years} years old',
    'common.salaryToSalary': '{salary1} to {salary2}',
    'common.fromSalary': 'From {salary}',
    'common.maximumOfSalary': 'Maximum of {salary}',
    'common.curriculum': 'Curriculum',
    'common.opportunity': 'Opportunity',
    'common.minimumAge': 'Minimum Age',
    'common.maxAge': 'Maximum Age',
    'common.salary': 'Salary',
    'common.minimumSalary': 'Minimum Salary',
    'common.maxSalary': 'Maximum Salary',
    'common.minimumSize': 'Minimum Size',
    'common.description': 'Description',
    'common.requestOf': 'Request of',
    'common.publishedIn': 'Published on',
    'common.logout': 'Logout',
    'common.add': 'Add',
    'common.newPlayer': 'New Player',
    'common.name': 'Name',
    'common.all': 'All',
    'common.age': 'Age',
    'common.from': 'From',
    'common.to': 'To',
    'common.availability': 'Availability',
    'common.available': 'Available',
    'common.unavailable': 'Unavailable',
    'common.clean': 'Clear',
    'common.level': 'Level',
    'common.agency': 'Agency',
    'common.close': 'Close',
    'common.country': 'Country',
    'common.withoutCountry': 'Without Country',
    'common.championship': 'Championship',
    'common.none': 'None',
    'common.coin': 'Currency',
    'common.minimumHeight': 'Minimum Height',
    'common.withoutDescription': 'Without Description',
    'common.renew': 'Renew',
    'common.addPlayer': 'Add Player',
    'common.back': 'Back',
    'common.addVideo': 'Add Video',
    'common.submitCV': 'Submit Curriculum',
    'common.right': 'Right',
    'common.left': 'Left',
    'common.height': 'Height',
    'common.weight': 'Weight',
    'common.category': 'Category',
    'common.videos': 'Videos',
    'common.sendProposal': 'Send Proposal',
    'common.proceed': 'Proceed',
    'common.responsible': 'Responsible',
    'common.cast': 'Cast',
    'common.loan': 'Loan',
    ...commonAlertsEn,
    ...commonPositionsEn,
    ...commonMenuEn,
    ...commonFormEn,
    ...commonLevelsEn,
    ...commonCountriesEn,
    ...commonCategoryEn
}
