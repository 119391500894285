import { authMessages } from "./auth";
import { commonPtBr } from "./common";
import { footerPtBr } from "./footer";
import { landingPageMessages } from "./landingPage";

export default {
  ...authMessages,
  ...commonPtBr,
  ...footerPtBr,
  ...landingPageMessages
};
