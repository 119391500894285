import { commonCountries } from "./countries"

const commonFormPtBr = {
  'common.form.email': 'Email',
  'common.form.fullName': 'Nome Completo',
  'common.form.telephone': 'Telefone',
  'common.form.password': 'Digite uma senha',
  'common.form.passwordConfirmation': 'Confirme a senha',
  'common.form.mainPosition': 'Posição Principal',
  'common.form.secondaryPosition': 'Posição Secundária',
  'common.form.birthdate': 'Data de Nascimento',
  'common.form.nationality': 'Nacionalidade',
  'common.form.doubleNationality': 'Dupla Nacionalidade',
  'common.form.isThePlayerAvailable': 'O jogador está atualmente disponível?',
  'common.form.dominantFoot': 'Pé Dominante',
  'common.form.playerVisibility': 'Visibilidade do Jogador',
  'common.form.onlyClubs': 'Somente Clubes',
  'common.form.clubsAndAgents': 'Clubes e Agentes',
  'common.form.agentName': 'Nome do Agente',
  'common.form.clubName': 'Nome do Clube',
  'common.form.startDate': 'Data de Início',
  'common.form.finalDate': 'Data Final',
  'common.form.hasAgent': 'Tem agente?',
  'common.form.nameAgency': 'Nome da Agência',
}

const commonAlerts = {
    'common.alerts.profileBeingEvaluated': 'Seu perfil está sendo avaliado pela nossa equipe. Logo entraremos em contato via WhatsApp no telefone informado em seu cadastro.',
    'common.alerts.selectOnePlayerToRegisterOpportunity': 'Selecione pelo menos 1 jogador para cadastrar na oportunidade!',
    'common.alerts.alreadyRegisteredForThisOpportunity': 'Você já se cadastrou nessa oportunidade!',
    'common.alerts.meetAllOpportunityRequirements': 'Caso cumpra todos os requisitos da oportunidade, o clube/agente receberá o seu currículo com seus dados de contato',
    'common.alerts.noPlayersRegistered': 'Você não tem nenhum jogador cadastrado. Faça pelo menos 1 cadastro para se inscrever na proposta!',

}

const commonPositions = {
    'common.position': 'Posição',
    'common.goalkeeper': 'Goleiro',
    'common.defender': 'Zagueiro',
    'common.rightBack': 'Lateral Direito',
    'common.leftBack': 'Lateral Esquerdo',
    'common.defensiveMidfielder': 'Volante',
    'common.midfielder': 'Meia',
    'common.winger': 'Extremo',
    'common.striker': 'Centroavante',
    'common.coach': 'Treinador',
    'common.agent': 'Agente',
    'common.club': 'Clube',
    'common.player': 'Jogador'
}

const commonMenu = {
    'common.myPlayers': 'Meus Jogadores',
    'common.search': 'Fazer buscar',
    'common.myProfile': 'Meu perfil',
    'common.proposals': 'Propostas',
    'common.monitoring': 'Monitoramento',
    'common.myVacancies': 'Minhas Vagas',
    'common.myVideos': 'Meus Vídeos',
}

const commonLevels = {
  'common.level.a': 'Série A',
  'common.level.b': 'Série B',
  'common.level.c': 'Série C',
  'common.level.d': 'Série D',
  'common.level.stateDivision': '1º Divisão Estadual',
  'common.level.base': 'Base',
  'common.level.anyDivision': 'Qualquer Divisão',
  'common.level.undefined': 'Não definido'
}

const commonCategory = {
  'common.base': 'Base',
  'common.professional': 'Profissional',
}

export const commonPtBr = {
    'common.start': 'Começar',
    'common.login': 'Entrar',
    'common.clubs': 'Clubes',
    'common.agents': 'Agentes',
    'common.players': 'Jogadores',
    'common.plans': 'Planos',
    'common.yes': 'Sim',
    'common.not': 'Não',
    'common.save': 'Salvar',
    'common.optional': 'Opcional',
    'common.attention': 'Atenção',
    'common.yearsOld': '{years} anos',
    'common.salaryToSalary': '{salary1} à {salary2}',
    'common.fromSalary': 'A partir de {salary}',
    'common.maximumOfSalary': 'Máximo de {salary}',
    'common.curriculum': 'Currículo',
    'common.opportunity': 'Oportunidade',
    'common.minimumAge': 'Idade Mínima',
    'common.maxAge': 'Idade Máxima',
    'common.salary': 'Salário',
    'common.minimumSalary': 'Salário Mínimo',
    'common.maxSalary': 'Salário Máximo',
    'common.minimumSize': 'Tamanho mínimo',
    'common.description': 'Descrição',
    'common.requestOf': 'Pedido de',
    'common.publishedIn': 'Publicada em',
    'common.logout': 'Sair',
    'common.add': 'Adicionar',
    'common.newPlayer': 'Novo Jogador',
    'common.name': 'Nome',
    'common.all': 'Todos',
    'common.age': 'Idade',
    'common.from': 'De',
    'common.to': 'Até',
    'common.availability': 'Disponibilidade',
    'common.available': 'Disponível',
    'common.unavailable': 'Não disponível',
    'common.clean': 'Limpar',
    'common.level': 'Nível',
    'common.agency': 'Agência',
    'common.close': 'Fechar',
    'common.country': 'País',
    'common.withoutCountry': 'Sem País',
    'common.championship': 'Campeonato',
    'common.none': 'Nenhum',
    'common.coin': 'Moeda',
    'common.minimumHeight': 'Altura Mínima',
    'common.withoutDescription': 'Sem Descrição',
    'common.renew': 'Renovar',
    'common.addPlayer': 'Adicionar Jogador',
    'common.back': 'Voltar',
    'common.addVideo': 'Adicionar Vídeo',
    'common.submitCV': 'Enviar Currículo',
    'common.right': 'Direito',
    'common.left': 'Esquerdo',
    'common.height': 'Altura',
    'common.weight': 'Peso',
    'common.category': 'Categoria',
    'common.videos': 'Vídeos',
    'common.sendProposal': 'Enviar Proposta',
    'common.proceed': 'Prosseguir',
    'common.responsible': 'Responsável',
    'common.cast': 'Elenco',
    'common.loan': 'Empréstimo',
    ...commonAlerts,
    ...commonPositions,
    ...commonMenu,
    ...commonFormPtBr,
    ...commonLevels,
    ...commonCountries,
    ...commonCategory
}
