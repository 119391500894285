export const landingPagePlansMessages = {
    'landingPage.plans.title': 'Our Plans',

    'landingPage.plans.player.title': 'For Player',
    'landingPage.plans.player.description': `<p>• Access market opportunities.</p>
        <p>• Send your resume directly to openings created by clubs and agents.</p>
        <p>• Be seen by agents and clubs worldwide.</p>
        <p>• Increase your chances of getting a good contract.</p>`,
    'landingPage.plans.player.text1': 'Did you know that currently 94% of Brazilian players end the year without a club?',

    'landingPage.plans.player.text2':
        'This might even be your reality. You finish a state championship and live with instability, uncertainty, not knowing if your contract will be renewed, not knowing if you will have a club in the coming months, or the uncertainty of moving up a category and continuing at the club, knowing that you have household obligations to fulfill, bills to pay.',

    'landingPage.plans.player.text3':
        'This is precisely why most players give up on their dream of playing professionally. You may never know if your materials will reach the hands of a big club or an influential agent, and every year brings the same uncertainties, the same struggles.',

    'landingPage.plans.player.text4':
        'It was with the aim of solving this problem that Transferfut was created, a platform that connects players with agents and clubs.',

    'landingPage.plans.player.text5': 'It is a way for you to showcase your materials and access offers from around the world!',

    'landingPage.plans.player.text6': 'You can start today for FREE.',

    'landingPage.plans.player.text7':
        'Click the button below, register at no cost, and discover the opportunities we have available today!',

    'landingPage.plans.clubs.title': 'For Club',
    'landingPage.plans.clubs.description': `<p>• Register your requests and receive only players with the characteristics you are looking for.</p>
        <p>• Send your players to requests from other clubs, increasing the chance of negotiating them.</p>
        <p>• See the players available for loan in other clubs.</p>
        <p>• Make your players available for loan so that other clubs can access them, speeding up negotiations.</p>
        <p>• Access the complete profile of athletes with their most relevant information.</p>`,
    'landingPage.plans.clubs.text1':
        'Most football clubs face the same dilemma. Every start of the championship is a rush to find players!',
    'landingPage.plans.clubs.text2':
        'Call a club, call an agent, always looking for free players, for loan or sale.',
    'landingPage.plans.clubs.text3':
        'Not to mention that often, the club ends up with players in its squad who are not part of the team’s plans, but simply because no offer or negotiation appears, it is necessary to keep them on the roster.',
    'landingPage.plans.clubs.text4':
        'Well, what if there was a way to announce all your players and also see the players available in the market?! Both those without a club and those available for loan or sale?',
    'landingPage.plans.clubs.text5':
        'And what if there was a way to close these partnerships and signings in a much simpler, more practical, and faster way?',
    'landingPage.plans.clubs.text6': 'Transferfut is a platform created exactly for this purpose!',
    'landingPage.plans.clubs.text7':
        'We created an online environment where clubs and agents can advertise their players, discover new athletes, make offers, and close deals.',
    'landingPage.plans.clubs.text8': 'It’s basically an e-commerce for players, connecting clubs, agents, and athletes!',
    'landingPage.plans.clubs.text9':
        'You can start today and for FREE! Just click the button below and discover the available players, analyze materials, and also promote those athletes who are up for negotiation. Don’t miss out!',

    'landingPage.plans.agents.title': 'For Agent',
    'landingPage.plans.agents.description': `
        <p>• Access club requests and send your players directly to them.</p>
        <p>• Register your requests and receive only players with the characteristics you are looking for.</p>
        <p>• Send your athletes to agent requests and make new partnerships.</p>
        <p>• Have your profile with all your players registered on our platform, thus being able to receive offers.</p>
        <p>• Send offers to all agents and clubs registered on our platform.</p>
    `,
    'landingPage.plans.agents.text1':
        'It is really frustrating to receive a good request from a club but not find the player for that opportunity.',
    'landingPage.plans.agents.text2':
        'Or the opposite, having good players in your portfolio but not finding clubs looking for that specific player.',
    'landingPage.plans.agents.text3':
        'This is increasingly common among agents. Having only one side of the negotiation and not being able to find the other.',
    'landingPage.plans.agents.text4': 'But imagine: would it help you if there was an online player trading platform?',
    'landingPage.plans.agents.text5':
        'Where clubs and agents post their vacancies, detailing the type of player they are looking for, and where agents and clubs can also promote their available players’ materials to always make new partnerships and generate new deals.',
    'landingPage.plans.agents.text6': 'It would be much easier to find teams for your players, right?',
    'landingPage.plans.agents.text7': 'And it would also be much easier to find players, new talents for your requests!',
    'landingPage.plans.agents.text8': 'It was with the aim of facilitating player trading that Transferfut was created.',
    'landingPage.plans.agents.text9':
        'A 100% online platform where you can register and find everything that is open for negotiation in the football world.',
    'landingPage.plans.agents.text10':
        'You can start for FREE, click the button below, register, and start negotiating!',
}
