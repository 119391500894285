const vacanciesAlerts = {
  'auth.vacanciesPage.alert.addedOpportunity': 'Oportunidade adicionada com sucesso!',
  'auth.vacanciesPage.alert.modifiedOpportunity': 'Oportunidade modificada com sucesso!',
  'auth.vacanciesPage.alert.excludedOpportunity': 'Oportunidade excluída com sucesso!',
}

export const authVacanciesPagesPtBr = {
  'auth.vacanciesPage.title': 'Minhas Vagas',
  'auth.vacanciesPage.newOpportunity': 'Nova Oportunidade',
  'auth.vacanciesPage.deleteThisOpportunity': 'Deseja realmente excluir essa oportunidade?',
  'auth.vacanciesPage.playedInTheLastFewYearsMinimum': 'Necessário ter jogado nos últimos anos mínimo',
  'auth.vacanciesPage.describeTheOpportunity': 'Descreva mais sobre essa oportunidade (Opcional)',
  ...vacanciesAlerts
}
