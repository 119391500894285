const authWelcomePageUserTypeEn = {
  'auth.welcomePage.userType.title': 'Hello, {user}',
  'auth.welcomePage.userType.subtitle': 'This is the place where players, agents, and clubs connect.',
  'auth.welcomePage.userType.youAre': 'You are...',
  'auth.welcomePage.userType.option.player': 'Player',
}

const authWelcomePagePlayerTypeEn = {
  'auth.welcomePage.playerType.title': 'Your Characteristics',
  'auth.welcomePage.playerType.form.youAreAvailable': 'Are you currently available?',
}

const authWelcomePagePlayerExperienceTypeEn = {
  'auth.welcomePage.playerExperience.title': 'Your Professional Experiences',
  'auth.welcomePage.playerExperience.subtitle': 'Your Professional Experiences are the clubs where you have played',

  'auth.welcomePage.playerExperience.alert.videoLinkIncorrect': 'The YouTube link is incorrect! It should be in this format: https://www.youtube.com/watch?v=aaaaaaaaaaa',
}

const authWelcomePageManagerTypeEn = {
  'auth.welcomePage.managerType.title': 'Tell us a bit about yourself',

  'auth.welcomePage.managerType.form.telephone': 'Contact Phone',
}

export const authWelcomePageEn = {
  'auth.welcomePage.title': 'Welcome',
  ...authWelcomePageUserTypeEn,
  ...authWelcomePagePlayerTypeEn,
  ...authWelcomePagePlayerExperienceTypeEn,
  ...authWelcomePageManagerTypeEn
}
