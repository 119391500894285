const authVideosPageAlertsEn = {
  'auth.videosPage.wantToDeleteTheVideo': 'Do you really want to delete the video?',
  'auth.videosPage.videoAddedSuccessfully': 'Video added successfully!',
}

export const authVideosPageEn = {
  'auth.videosPage.title': 'My Videos',
  'auth.videosPage.youtubeVideoLink': 'YouTube Video Link',
  'auth.videosPage.invalidVideoUrl': 'The URL is invalid, please try again!',
  ...authVideosPageAlertsEn
}
