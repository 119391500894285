import { authMessages } from "./auth";
import { commonEn } from "./common";
import { footerEn } from "./footer";
import { landingPageMessages } from "./landingPage";

export default {
  ...authMessages,
  ...commonEn,
  ...footerEn,
  ...landingPageMessages
};
