export const landingPageSuccessCaseMessages = {
    'landingPage.successCase.title': 'Alguns casos de sucesso',
    'landingPage.successCase.titlePlayer1': 'MOYSÉS É DO GOIÁS',
    'landingPage.successCase.descriptionPlayer1':
        'O Atleta Moysés assinou com o Goiás E.C. A conexão aconteceu entre o clube e a empresa Rx Sports através da nossa plataforma.',
    'landingPage.successCase.titlePlayer2': 'JARDEL NO ATLETICO ALAGOINHAS',
    'landingPage.successCase.descriptionPlayer2': 'O clube baiano acertou a contratação do volante através da plataforma.',
    'landingPage.successCase.titlePlayer3': 'MARCOS É DO VILA NOVA',
    'landingPage.successCase.descriptionPlayer3':
        'O Vila Nova, através da Transferfut, fez a captação do lateral Marcos para intregar sua equipe do sub-20.',
    'landingPage.successCase.titlePlayer4': 'Luis Felipe no Guarany F.C',
    'landingPage.successCase.descriptionPlayer4':
        'Através da Transferfut as agências CCP Sports e Lf90 sports fecharam o atleta Luis Felipe no Guarany Futebol Clube',
    'landingPage.successCase.titlePlayer5': 'LUCAS SOUZA NO SPORTING FONTE NOVA',
    'landingPage.successCase.descriptionPlayer5': 'O clube fez a captação do atleta Lucas Souza através da plataforma.',
    'landingPage.successCase.titlePlayer6': 'MATHEUS LEAL NO ATLÉTICO ALAGOINHAS',
    'landingPage.successCase.descriptionPlayer6':
        'O Atlético Alagoinhas acertou a contração do Lateral Esquerdo Matheus Leal. A conexão foi feita através da nossa plataforma.',
    'landingPage.successCase.titlePlayer7': 'WELLINGTON NO BETIM',
    'landingPage.successCase.descriptionPlayer7':
        'O clube Betim fechou a contratação do atleta Wellington da empresa Pro Talent Sports através da nossa plataforma.',
    'landingPage.successCase.titlePlayer8': 'CESINHA NO ATLÉTICO ALAGOINHAS',
    'landingPage.successCase.descriptionPlayer8':
        'O Atlético Alagoinhas anunciou a contração do atacante Cesinha através da nossa plataforma.',

}