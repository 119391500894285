import { landingPageDipositionMessages } from "./diposition";
import { landingPagePlansMessages } from "./plans";
import { landingPageSuccessCaseMessages } from "./successCase";

export const landingPageMessages = {
    'landingPage.wallpaper.title': 'Aqui nasce a conexão entre Jogadores, Agentes e Clubes.',
    'landingPage.wallpaper.subtitle': 'Construímos oportunidades no mercado do futebol: conectamos Jogadores, Agentes e Clubes; Aceleramos e potencializamos o processo de contratações e transferências.',

    'landingPage.partners.title': 'Alguns de nossos parceiros',

    ...landingPageDipositionMessages,
    ...landingPagePlansMessages,
    ...landingPageSuccessCaseMessages
}