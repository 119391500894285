const vacanciesAlertsEn = {
  'auth.vacanciesPage.alert.addedOpportunity': 'Opportunity added successfully!',
  'auth.vacanciesPage.alert.modifiedOpportunity': 'Opportunity modified successfully!',
  'auth.vacanciesPage.alert.excludedOpportunity': 'Opportunity deleted successfully!',
}

export const authVacanciesPagesEn = {
  'auth.vacanciesPage.title': 'My Vacancies',
  'auth.vacanciesPage.newOpportunity': 'New Opportunity',
  'auth.vacanciesPage.deleteThisOpportunity': 'Do you really want to delete this opportunity?',
  'auth.vacanciesPage.playedInTheLastFewYearsMinimum': 'Required to have played in the last few years minimum',
  'auth.vacanciesPage.describeTheOpportunity': 'Describe more about this opportunity (Optional)',
  ...vacanciesAlertsEn
}
