export const landingPageDipositionMessages = {
    'landingPage.deposition.title': 'Depoimentos',

    'landingPage.deposition.depositionTitle1': 'Harlei Menezes',
    'landingPage.deposition.depositionSubtitle1': 'Vice Presidente de futebol do Goiás Esporte Clube',
    'landingPage.deposition.depositionDescription1':
        'A transferfut veio para facilitar e tem aberto portas para a conexão do Goiás com os agentes e clubes de todo o Brasil, facilitando as negociações e aumentando nossa rede de network.',

    'landingPage.deposition.depositionTitle2': 'Olimpio Jayme',
    'landingPage.deposition.depositionSubtitle2': 'Diretor de futebol de base do Vila Nova Fc',
    'landingPage.deposition.depositionDescription2':
        'A transferfut chegou para facilitar o relacionamento entre clubes, agentes e jogadores. A tecnologia da informação veio para auxiliar o dia a dia de pessoas e empresas, e no futebol não poderia ser diferente. Nós, do Vila Nova, estamos usando para facilitar a captação de jogadores e tem nos ajudado bastante!',

    'landingPage.deposition.depositionTitle3': 'Entourage Sports',
    'landingPage.deposition.depositionSubtitle3': 'Empresa de agenciamento de atletas',
    'landingPage.deposition.depositionDescription3':
        'A transferfut veio para revolucionar o mercado do futebol. Baseando-se em network e parcerias entre clubes, agentes e jogadores, muitos talentos tem sido descobertos pela plataforma.',

    'landingPage.deposition.depositionTitle4': 'Jardel',
    'landingPage.deposition.depositionSubtitle4': 'Atleta profissional',
    'landingPage.deposition.depositionDescription4':
        'Através da plataforma Transferfut consegui assinar contrato com o atual campeão baiano. Acabei me cadastrando para uma proposta, de imediato, o dirigente entrou em contato comigo e logo começamos a negociação! De forma simples e objetiva, entramos em acordo e assinei com o Atlético de Alagoinhas. Gostaria de ressaltar a importância da plataforma Transferfut, que irá facilitar muito a vida dos atletas, agentes e clubes, assim como facilitou a minha. Fica aqui o meu agradecimento!',
}