import { authAthletesPagePtBr } from "./athletesPage";
import { authCurriculumPagePtBr } from "./curriculumPage";
import { authHomePageMessages } from "./homePage";
import { loginMessages } from "./login";
import { authMakeVideosPagePtBr } from "./makeVideosPage";
import { authMonitoringPage } from "./monitoringPage";
import { authMyProfileMessages } from "./myProfilePage";
import { authProfileAthletePagePtBr } from "./profileAthletePage";
import { authSearchPagePtBr } from "./searchPage";
import { authVacanciesPagesPtBr } from "./vacanciesPage";
import { authVideosPagePtBr } from "./videosPage";
import { authWelcomePagePtBr } from "./welcomePage";

export const authMessages = {
    ...authHomePageMessages,
    ...authMyProfileMessages,
    ...loginMessages,
    ...authMonitoringPage,
    ...authSearchPagePtBr,
    ...authVacanciesPagesPtBr,
    ...authAthletesPagePtBr,
    ...authProfileAthletePagePtBr,
    ...authMakeVideosPagePtBr,
    ...authVideosPagePtBr,
    ...authCurriculumPagePtBr,
    ...authWelcomePagePtBr
}
