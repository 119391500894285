export const authHomePageMessages = {
    'auth.homePage.newProposals': 'Novas Propostas',
    'auth.homePage.sendProposals': 'Enviar Proposta',
    'auth.homePage.proposalSentSuccessfully': 'Proposta enviada com sucesso!',
    'auth.homePage.messageToOwner': 'Mensagem para o dono (a) da proposta:',
    'auth.homePage.confirmRequirements': 'Confirmo que cumpro todos os requisitos acima.',
    'auth.homePage.submitCV': 'Enviar Currículo',
    'auth.homePage.opportunitiesFound': 'Oportunidades Encontradas',
    'auth.homePage.necessaryToHavePlayedInRecentYears': 'Necessário ter jogado nos últimos anos no minímo',
    'auth.homePage.opportunityExpired': 'Oportunidade Expirada',
}