export const loginMessages = {
  'auth.login.title': 'LOG IN',
  'auth.register.title': 'Sign Up for free',
  'auth.login.header': 'LOG IN',
  'auth.register.header': 'Fill in the columns below with your complete details',
  'auth.login.tab1': 'Log In',
  'auth.login.tab2': 'Create Account',
  'auth.login.stayConnected': 'Stay connected',
  'auth.login.forgotPassword': 'Forgot my password',
  'auth.login.termsAndPrivacyPolicy':
      'By registering on Transferfut you agree to all our {term} and our {privacyPolicy}.',
  'auth.login.changePassword': 'Change Password',
  'auth.login.resetPassword': 'Reset Password',
}
