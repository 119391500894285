export const authMakeVideosPageEn = {
  'auth.makeVideosPage.title': 'Make Your Video',
  'auth.makeVideosPage.info': `
    <p>Have you ever heard the phrase: First impressions last?</p>
    <p>Well, in the world of football, this is also true.</p>
    <p>
      If an agent or club official opens your material, your videos, and from the start the quality is poor,
      your chances of getting a good contract decrease significantly.
    </p>

    <p>
      Believe it, this is your showcase, and the higher the quality, the greater the chances your material
      will reach a top team.
    </p>

    <p>We have a team of qualified professionals ready to help you.</p>

    <p>
      Get your quote today with no obligation; our professionals will further enhance your skills.
    </p>
  `,
  'auth.makeVideosPage.benefits': 'Benefits',
  'auth.makeVideosPage.benefits.info1': 'With good material, the greater the chances of getting a good club',
  'auth.makeVideosPage.benefits.info2': 'Show your best football through the material.',
  'auth.makeVideosPage.contactUs': 'Contact Us',
}
