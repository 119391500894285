export const authMakeVideosPagePtBr = {
  'auth.makeVideosPage.title': 'Faça seu vídeo',
  'auth.makeVideosPage.info': `
    <p>Você já ouviu aquela frase: A primeira impressão é a que fica?</p>
    <p>Bem, no mundo do futebol isso também acontece.</p>
    <p>
      Se um agente ou dirigente de um clube abrir seu material, seus vídeos e desde o princípio a
      qualidade for ruim, suas chances de conseguir um bom contrato diminuem muito.
    </p>

    <p>
      Acredite, essa é sua vitrine e quanto maior a qualidade, maior serão as chances de seu material
      chegar em um grande time.
    </p>

    <p>Nós temos uma equipe de profissionais qualificados, prontos para te ajudar.</p>

    <p>
      Faça hoje mesmo seu orçamento sem compromisso, nossos profissionais valorizarão ainda mais suas
      habilidades.
    </p>
  `,
  'auth.makeVideosPage.benefits': 'Vantages',
  'auth.makeVideosPage.benefits.info1': 'Com um bom material, maiores as chances de conseguir um bom clube',
  'auth.makeVideosPage.benefits.info2': 'Mostrar o seu melhor futebol através do material.',
  'auth.makeVideosPage.contactUs': 'Entre em contato',
}
