import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import Cookies from 'js-cookie';

import { IntlProvider } from 'react-intl';
import { loadLocale } from '@app/locales';

interface LanguageContextData {
  language: string;
  changeLanguage: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextData>({
  language: 'pt-BR',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeLanguage: () => {},
});

export const useLanguage = (): LanguageContextData => useContext(LanguageContext);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider = ({ children }: LanguageProviderProps): JSX.Element => {
  const currentLanguage = Cookies.get('lang');
  const [language, setLanguage] = useState<string>(currentLanguage || 'pt-BR');

  useEffect(() => {
    Cookies.set('lang', language);
  }, [language]);

  const changeLanguage = (lang: string) => {
    window.location.reload();
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      <IntlProvider locale={language} messages={loadLocale(language)}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
