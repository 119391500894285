export const landingPageDipositionMessages = {
    'landingPage.deposition.title': 'Testimonials',

    'landingPage.deposition.depositionTitle1': 'Harlei Menezes',
    'landingPage.deposition.depositionSubtitle1': 'Vice President of Football at Goiás Esporte Clube',
    'landingPage.deposition.depositionDescription1':
        'Transferfut came to facilitate and has opened doors for Goiás to connect with agents and clubs throughout Brazil, making negotiations easier and expanding our network.',

    'landingPage.deposition.depositionTitle2': 'Olimpio Jayme',
    'landingPage.deposition.depositionSubtitle2': 'Director of Youth Football at Vila Nova FC',
    'landingPage.deposition.depositionDescription2':
        'Transferfut arrived to facilitate relationships between clubs, agents, and players. Information technology has come to assist the daily life of people and companies, and it could not be different in football. We at Vila Nova are using it to simplify player recruitment, and it has helped us a lot!',

    'landingPage.deposition.depositionTitle3': 'Entourage Sports',
    'landingPage.deposition.depositionSubtitle3': 'Athlete Management Company',
    'landingPage.deposition.depositionDescription3':
        'Transferfut came to revolutionize the football market. Based on networking and partnerships between clubs, agents, and players, many talents have been discovered through the platform.',

    'landingPage.deposition.depositionTitle4': 'Jardel',
    'landingPage.deposition.depositionSubtitle4': 'Professional Athlete',
    'landingPage.deposition.depositionDescription4':
        'Through the Transferfut platform, I managed to sign a contract with the current champion of Bahia. I ended up signing up for a proposal, and immediately, the manager contacted me and we quickly started negotiations! In a simple and straightforward way, we reached an agreement, and I signed with Atlético de Alagoinhas. I would like to highlight the importance of the Transferfut platform, which will greatly facilitate the lives of athletes, agents, and clubs, as it did for me. Here is my thanks!',
}
