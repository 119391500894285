export const loginMessages = {
  'auth.login.title': 'FAÇA SEU LOGIN',
  'auth.register.title': 'Faça seu cadastro gratuito',
  'auth.login.header': 'ENTRAR',
  'auth.register.header': 'Preencha as colunas abaixo com seus dados completos',
  'auth.login.tab1': 'Fazer login',
  'auth.login.tab2': 'Criar Conta',
  'auth.login.stayConnected': 'Manter conectado',
  'auth.login.forgotPassword': 'Esqueci minha senha',
  'auth.login.termsAndPrivacyPolicy':
    'Ao se cadastrar na Transferfut você concorda com todos os nossos {term} e nossa {privacyPolicy}.',
  'auth.login.changePassword': 'Alterar Senha',
  'auth.login.resetPassword': 'Resetar senha',
}
