import ptBR from './pt-BR';
import enUS from './en-US';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function loadLocale(locale: string) {
  switch (locale) {
    case 'pt-BR':
      return ptBR;
    case 'en-US':
      return enUS;
    default:
      return ptBR;
  }
}
