export const commonCountriesEn = {
  'Sem País': 'Without Country',
  'Albânia': 'Albania',
  'Andorra': 'Andorra',
  'Argentina': 'Argentina',
  'Armênia': 'Armenia',
  'Austrália': 'Australia',
  'Áustria': 'Austria',
  'Azerbaijão': 'Azerbaijan',
  'Bielorrússia': 'Belarus',
  'Bélgica': 'Belgium',
  'Bolívia': 'Bolivia',
  'Bósnia e Herzegovina': 'Bosnia and Herzegovina',
  'Bulgária': 'Bulgaria',
  'Canadá': 'Canada',
  'Chile': 'Chile',
  'China': 'China',
  'Colômbia': 'Colombia',
  'Croácia': 'Croatia',
  'Chipre': 'Cyprus',
  'República Tcheca': 'Czech Republic',
  'Dinamarca': 'Denmark',
  'Equador': 'Ecuador',
  'Egito': 'Egypt',
  'Reino Unido': 'United Kingdom',
  'Estônia': 'Estonia',
  'Finlândia': 'Finland',
  'França': 'France',
  'Geórgia': 'Georgia',
  'Alemanha': 'Germany',
  'Grécia': 'Greece',
  'Hungria': 'Hungary',
  'Islândia': 'Iceland',
  'Índia': 'India',
  'Indonésia': 'Indonesia',
  'Irã': 'Iran',
  'Irlanda': 'Ireland',
  'Israel': 'Israel',
  'Itália': 'Italy',
  'Japão': 'Japan',
  'Cazaquistão': 'Kazakhstan',
  'Kuwait': 'Kuwait',
  'Lituânia': 'Lithuania',
  'Luxemburgo': 'Luxembourg',
  'Malásia': 'Malaysia',
  'Malta': 'Malta',
  'México': 'Mexico',
  'Moldávia': 'Moldova',
  'Montenegro': 'Montenegro',
  'Holanda': 'Netherlands',
  'Nova Zelândia': 'New Zealand',
  'Macedônia': 'North Macedonia',
  'Irlanda do Norte': 'Northern Ireland',
  'Noruega': 'Norway',
  'Paraguai': 'Paraguay',
  'Peru': 'Peru',
  'Polônia': 'Poland',
  'Portugal': 'Portugal',
  'Catar': 'Qatar',
  'Romênia': 'Romania',
  'Rússia': 'Russia',
  'Arábia Saudita': 'Saudi Arabia',
  'Sérvia': 'Serbia',
  'Cingapura': 'Singapore',
  'Eslováquia': 'Slovakia',
  'Eslovênia': 'Slovenia',
  'África do Sul': 'South Africa',
  'Coreia do Norte': 'North Korea',
  'Espanha': 'Spain',
  'Suécia': 'Sweden',
  'Suíça': 'Switzerland',
  'Tailândia': 'Thailand',
  'Turquia': 'Turkey',
  'Ucrânia': 'Ukraine',
  'Emirados Árabes Unidos': 'United Arab Emirates',
  'Uruguai': 'Uruguay',
  'Estados Unidos': 'United States',
  'Venezuela': 'Venezuela',
  'Vietnã': 'Vietnam',
  'País de Gales': 'Wales',
  'Brasil': 'Brazil'
}
