export const authMyProfileMessagesEn = {
  'auth.myProfile.title': 'My Profile',
  'auth.myProfile.fullName': 'Full Name',
  'auth.myProfile.birthDate': 'Date of Birth',
  'auth.myProfile.nationality': 'Nationality',
  'auth.myProfile.doubleNationality': 'Dual Nationality (if any)',
  'auth.myProfile.telephone': 'Telephone',
  'auth.myProfile.email': 'Email',
  'auth.myProfile.hasAgent': 'Do you have an agent?',
  'auth.myProfile.agentName': 'Agent Name',
  'auth.myProfile.availableToMarket': 'Are you currently available for the market?',
  'auth.myProfile.cpfCnpj': 'CPF or CNPJ',
  'auth.myProfile.agencyName': 'Agency Name',
  'auth.myProfile.updateProfileAlert': 'Profile updated successfully!',
  'auth.myProfile.doubleNationalityQuestion': 'Do you have dual nationality?'
}
