export const authMyProfileMessages = {
  'auth.myProfile.title': 'Meu Perfil',
  'auth.myProfile.fullName': 'Nome Completo',
  'auth.myProfile.birthDate': 'Data de Nascimento',
  'auth.myProfile.nationality': 'Nacionalidade',
  'auth.myProfile.doubleNationality': 'Dupla Nacionalidade (se houver)',
  'auth.myProfile.telephone': 'Telefone',
  'auth.myProfile.email': 'E-mail',
  'auth.myProfile.hasAgent': 'Tem agente?',
  'auth.myProfile.agentName': 'Nome Agente',
  'auth.myProfile.availableToMarket': 'Você está atualmente disponível para o mercado?',
  'auth.myProfile.cpfCnpj': 'CPF ou CNPJ',
  'auth.myProfile.agencyName': 'Nome da Agência',
  'auth.myProfile.updateProfileAlert': 'Perfil atualizado com sucesso!',
  'auth.myProfile.doubleNationalityQuestion': 'Possui dupla nacionalidade?',
}
